import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function NotFound() {
  const themeMode = useSelector((state) => state.themeMode);
  const { t } = useTranslation("common");

  return (
    <Grid item xs={12}>
      <Typography
        variant="h3"
        component="h1"
        sx={{
          color: themeMode === "light" ? "primary.main" : "secondary.main",
        }}
      >
        {t("common.notFound")}
      </Typography>
    </Grid>
  );
}

export default withAuthenticationRequired(NotFound);
