import { useSelector } from "react-redux";
import DataTableBase from "./DataTableBase";
import { useTranslation } from "react-i18next";
import React from "react";
import { Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

export default function ResidencesTable() {
  const residences = useSelector((state) => state.residences);
  const establishments = useSelector((state) => state.establishments);
  const devices = useSelector((state) => state.devices);

  const theme = useTheme();

  const { t } = useTranslation("common");

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: t("common.name"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography
              variant="body2"
              component="div"
              onClick={() => {
                window.location.href = `/residences/${tableMeta.rowData[0]}`;
              }}
              sx={{ color: theme.palette.primary.main, cursor: "pointer" }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "establishment_id",
      label: t("common.establishment"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let establishment = establishments.find(
            (establishment) => parseInt(establishment.id) === parseInt(value)
          );

          return establishment ? establishment.name : "";
        },
      },
    },
    {
      name: "water_deveui",
      label: t("common.water_meter"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let device = devices.find((device) => device.dev_eui === value);
          return device ? device.dev_eui : "";
        },
      },
    },
    {
      name: "elec_deveui",
      label: t("common.electricity_meter"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let device = devices.find((device) => device.dev_eui === value);
          return device ? device.dev_eui : "N/A";
        },
      },
    },
    {
      name: "screen_deveui",
      label: t("common.screen"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let device = devices.find((device) => device.dev_eui === value);
          return device ? device.dev_eui : "";
        },
      },
    },
    {
      name: "current_occupation",
      label: t("common.current_occupation"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "capacity",
      label: t("common.capacity"),
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const [selectedResidences, setSelectedresidences] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const tableActions = [
    {
      name: t("common.delete_selected", {
        name: t("common.residences"),
        count: selectedResidences.length,
      }),
      icon: "Delete",
      action: () => {},
    },
  ];

  return (
    <DataTableBase
      columns={columns}
      data={residences}
      title={"Residences"}
      id="residence-list"
      name="residence-list"
      pageSize={10}
      selectedItems={selectedResidences}
      setSelectedItems={setSelectedresidences}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      selectableRows="multiple"
      actions={tableActions}
      sortOrder="dev_eui"
    />
  );
}
