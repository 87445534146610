import { withAuthenticationRequired } from "@auth0/auth0-react";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import EstablishmentsTable from "../components/tables/Establishments";
import { useEffect } from "react";

function Establishments(props) {
  const { t } = useTranslation("common");

  useEffect(() => {
    store.dispatch({
      type: "pageTitle/update",
      payload: t("common.establishments"),
    });

    let pageActions = [
      {
        name: t("common.add_new", { name: t("common.establishment") }),
        icon: "Add",
        url: "/add/establishment",
      },
    ];

    store.dispatch({ type: "pageActions/update", payload: pageActions });
  }, [t]);

  return (
    <Grid item xs={12}>
      <EstablishmentsTable />
    </Grid>
  );
}

export default withAuthenticationRequired(Establishments);
