import fetchData from "../API/Fetch";
import { store } from "../store";

export default async function fetchDevices() {
  let result = await fetchData(`/devices/`);

  store.dispatch({
    type: "devices/update",
    payload: result ? result : [],
  });
  return result;
}
