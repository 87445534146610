import SideMenu from "./SideMenu";
import AppNavbar from "./AppNavbar";
import { Alert, IconButton, Snackbar, Stack } from "@mui/material";
import Header from "./Header";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { handleSnackbarClose } from "../../functions/handleSnackbarClose";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import PageActions from "./PageActions";

export default function Layout(props) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const pageTitle = useSelector((state) => state.pageTitle);
  const themeMode = useSelector((state) => state.themeMode);
  const snackbar = useSelector((state) => state.snackbar);

  const { t } = useTranslation("common");

  useEffect(() => {
    setSnackbarOpen(snackbar.open);
    setSnackbarMessage(snackbar.message);
  }, [snackbar]);

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: themeMode === "dark" ? "#333" : "#f4f4f4",
      }}
    >
      <SideMenu t={t} />
      <AppNavbar />
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          backgroundColor:
            themeMode === "dark" ? "rgba(0,0,0,0.9)" : "rgba(0,0,0,0.025)",
          overflow: "auto",
        })}
      >
        <Stack
          spacing={2}
          sx={{
            alignItems: "center",
            mx: 3,
            pb: 5,
            mt: { xs: 8, md: 0 },
          }}
        >
          <Header pageTitle={pageTitle} themeMode={themeMode} t={t} />
          <Box
            sx={{
              width: "100%",
              maxWidth: {
                sm: "100%",
                md: "1700px",
                height: "calc(100vh - 108px)",
                overflow: "hidden",
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Typography
                  variant="h4"
                  component="h1"
                  gutterBottom
                  sx={{ color: themeMode === "dark" ? "white" : "black" }}
                >
                  {pageTitle}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <PageActions />
              </Grid>
              {props.children}
            </Grid>
          </Box>
        </Stack>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity ? snackbar.severity : "info"}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
