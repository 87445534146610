import { useSelector } from "react-redux";
import DataTableBase from "./DataTableBase";
import { useTranslation } from "react-i18next";
import React from "react";
import { useTheme } from "@mui/material";
import { Typography } from "@mui/material";

export default function DevicesTable() {
  const devices = useSelector((state) => state.devices);
  const theme = useTheme();

  const [selectedDevices, setSelecteddevices] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const { t } = useTranslation("common");

  const columns = [
    {
      name: "dev_eui",
      label: "Dev EUI",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography
              variant="body2"
              component="div"
              onClick={() => {
                window.location.href = `/devices/${value}`;
              }}
              sx={{ color: theme.palette.primary.main, cursor: "pointer" }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "name",
      label: t("common.name"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "device_type",
      label: t("common.device_type"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? value.name : "N/A";
        },
      },
    },
    {
      name: "fcnt",
      label: t("common.fcnt"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "time_updated",
      label: t("common.last_seen"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return new Date(value).toLocaleString();
        },
      },
    },
  ];

  const tableActions = [
    {
      name: t("common.delete_selected", {
        name: t("common.devices"),
        count: selectedDevices.length,
      }),
      icon: "Delete",
      action: () => {},
    },
  ];

  return (
    <DataTableBase
      columns={columns}
      data={devices}
      title={"Devices"}
      id="device-list"
      name="device-list"
      pageSize={10}
      selectedItems={selectedDevices}
      setSelectedItems={setSelecteddevices}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      selectableRows="multiple"
      actions={tableActions}
      sortOrder="dev_eui"
    />
  );
}
