const initialState = [];

function brandsReducer(state = initialState, action) {
  switch (action.type) {
    case "brands/update":
      return action.payload;
    default:
      return state;
  }
}

export default brandsReducer;
