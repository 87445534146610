import fetchData from "../API/Fetch";
import { store } from "../store";

export default async function fetchBrands() {
  let result = await fetchData(`/brands/`);

  store.dispatch({
    type: "brands/update",
    payload: result ? result : [],
  });
  return result;
}
