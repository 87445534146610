import { withAuthenticationRequired } from "@auth0/auth0-react";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function Dashboard(props) {
  const { t } = useTranslation("common");

  useEffect(() => {
    store.dispatch({
      type: "pageTitle/update",
      payload: t("common.dashboard"),
    });
  }, [t]);

  return <></>;
}

export default withAuthenticationRequired(Dashboard);
