import { postData } from "../API/Post";
import { store } from "../store";

export default async function fetchUser(user) {
  if (!user) return;
  let result = await postData("/users/", { email: user.email });

  store.dispatch({
    type: "userDB/update",
    payload: result ? result.data : [],
  });
  return result.data;
}
