import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { FormControl } from "@mui/material";
import { postData } from "../API/Post";
import updateSnackbarMessage from "../functions/updateSnackbarMessage";
import { Button } from "@mui/material";
import { Stack } from "@mui/material";
import { putData } from "../API/Put";

function EstablishmentUpdate() {
  const [addOrUpdate, setAddOrUpdate] = useState("add");
  const establishments = useSelector((state) => state.establishments);

  const brands = useSelector((state) => state.brands);
  const [brandId, setBrandId] = useState(null);

  const [establishmentId, setEstablishmentId] = useState(null);

  const params = useParams();

  const { t } = useTranslation("common");

  const { register, handleSubmit, control, reset } = useForm({
    reValidateMode: "onBlur",
    defaultValues: {},
  });

  const validationLabels = {
    name: {
      required: t("common.required", { name: t("common.name") }),
    },
    brand: {
      required: t("common.required", { name: t("common.brand") }),
    },
    stars: {
      required: t("common.required", { name: t("common.stars") }),
      min: t("common.min") + " 0",
      max: t("common.max") + " 5",
    },

    yellow_water_limit_per_person: {
      number: t("common.number_only"),
    },
    yellow_elec_limit_per_person: {
      number: t("common.number_only"),
    },
    red_water_limit_per_person: {
      number: t("common.number_only"),
    },
    red_elec_limit_per_person: {
      number: t("common.number_only"),
    },
    screen_brightness: {
      number: t("common.number_only"),
      min: t("common.min") + " 0",
      max: t("common.max") + " 100",
    },
    uplink_rate_seconds: {
      number: t("common.number_only"),
    },
    screen_on_start: {
      number: t("common.number_only"),
      min: t("common.min") + " 0",
      max: t("common.max") + " 23",
    },
    screen_on_end: {
      number: t("common.number_only"),
      min: t("common.min") + " 0",
      max: t("common.max") + " 23",
    },
  };

  const onSubmit = async (data) => {
    let {
      name,
      yellow_water_limit_per_person,
      yellow_elec_limit_per_person,
      red_water_limit_per_person,
      red_elec_limit_per_person,
      screen_brightness,
      uplink_rate_seconds,
      screen_on_start,
      screen_on_end,
      stars,
    } = data;

    let jsonData = {
      name: name,
      brand_id: brandId,
      stars: stars,
      yellow_water_limit_per_person: yellow_water_limit_per_person,
      yellow_elec_limit_per_person: yellow_elec_limit_per_person,
      red_water_limit_per_person: red_water_limit_per_person,
      red_elec_limit_per_person: red_elec_limit_per_person,
      screen_brightness: screen_brightness,
      uplink_rate_seconds: uplink_rate_seconds,
      screen_on_start: screen_on_start,
      screen_on_end: screen_on_end,
    };

    try {
      let response = null;

      if (establishmentId) {
        response = await putData(
          `/establishments/${establishmentId}/`,
          jsonData
        );
      } else {
        response = await postData(`/establishments/`, jsonData);
      }

      if (response.status === 200) {
        updateSnackbarMessage(
          addOrUpdate === "add"
            ? t("common.added_success", { name: t("common.establishment") }) +
                " Redirecting to Establishments..."
            : t("common.updated_success", { name: t("common.establishment") }) +
                " Redirecting to Establishments..."
        );

        //set timeout for 2 seconds and redirect to /establishments
        setTimeout(() => {
          window.location.href = "/establishments/";
        }, 3000);
      } else {
        updateSnackbarMessage(
          addOrUpdate === "add"
            ? t("common.add_error", { name: t("common.establishment") })
            : t("common.update_error", { name: t("common.establishment") })
        );
      }
    } catch (error) {
      updateSnackbarMessage(
        addOrUpdate === "add"
          ? t("common.add_error", { name: t("common.establishment") }) + error
          : t("common.update_error", { name: t("common.establishment") }) +
              error
      );
    }
  };

  useEffect(() => {
    //check to see if there is an id in the url
    if (params.id) {
      setEstablishmentId(params.id);
      setAddOrUpdate("update");

      let establishment = establishments.find(
        (establishment) => parseInt(establishment.id) === parseInt(params.id)
      );

      console.log("establishment", establishment);

      if (!establishment) return;

      reset({
        name: establishment.name,
        brand: establishment.brand_id,
        stars: establishment.stars,
        yellow_water_limit_per_person:
          establishment.yellow_water_limit_per_person,
        yellow_elec_limit_per_person:
          establishment.yellow_elec_limit_per_person,
        red_water_limit_per_person: establishment.red_water_limit_per_person,
        red_elec_limit_per_person: establishment.red_elec_limit_per_person,
        screen_brightness: establishment.screen_brightness,
        uplink_rate_seconds: establishment.uplink_rate_seconds,
        screen_on_start: establishment.screen_on_start,
        screen_on_end: establishment.screen_on_end,
      });

      setBrandId(establishment.brand_id);

      store.dispatch({
        type: "pageTitle/update",
        payload: t("common.update") + " " + t("common.establishment"),
      });
    } else {
      setEstablishmentId(null);
      store.dispatch({
        type: "pageTitle/update",
        payload: t("common.add_new", { name: t("common.establishment") }),
      });
    }
  }, [params, establishments, addOrUpdate, t, reset]);

  const [showLimits, setShowLimits] = useState(false);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} direction={"row"}>
        <Grid item xs={4}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="name"
                  label={t("common.name")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.name[error.type] : ""}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...register("name")}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="stars"
            control={control}
            rules={{
              required: true,
              min: 0,
              max: 5,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="stars"
                  label={t("common.stars")}
                  fullWidth
                  {...field}
                  type="number"
                  error={error !== undefined}
                  helperText={error ? validationLabels.stars[error.type] : ""}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...register("stars")}
                />
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="brand"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 2,
                }}
              >
                <Autocomplete
                  {...field}
                  id="brand"
                  options={brands}
                  getOptionLabel={(option) => option.name}
                  defaultValue={
                    brands.find((brand) => brand.id === brandId) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("common.brand")}
                      error={error !== undefined}
                      helperText={
                        error ? validationLabels.brand[error.type] : ""
                      }
                      variant="outlined"
                    />
                  )}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e, data) => setBrandId(data.id)}
                />
              </FormControl>
            )}
          />
        </Grid>

        {showLimits && (
          <>
            <Grid item xs={3}>
              <Controller
                name="yellow_water_limit_per_person"
                control={control}
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="yellow_water_limit_per_person"
                      label={t("common.yellow_water_limit_per_person")}
                      type={"number"}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              Liters
                            </InputAdornment>
                          ),
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.yellow_water_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("yellow_water_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="yellow_elec_limit_per_person"
                control={control}
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="yellow_elec_limit_per_person"
                      label={t("common.yellow_elec_limit_per_person")}
                      fullWidth
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">kWh</InputAdornment>
                          ),
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                      {...field}
                      type={"number"}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.yellow_elec_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("yellow_elec_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="red_water_limit_per_person"
                control={control}
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="red_water_limit_per_person"
                      label={t("common.red_water_limit_per_person")}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              Liters
                            </InputAdornment>
                          ),
                        },
                      }}
                      type={"number"}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.red_water_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("red_water_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="red_elec_limit_per_person"
                control={control}
                //rules, number only
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="red_elec_limit_per_person"
                      label={t("common.red_elec_limit_per_person")}
                      fullWidth
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">kWh</InputAdornment>
                          ),
                        },
                      }}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.red_elec_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("red_elec_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="screen_brightness"
                control={control}
                //rules number only, 0 - 100
                rules={{
                  number: true,
                  min: 0,
                  max: 100,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="screen_brightness"
                      label={t("common.screen_brightness")}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        },
                      }}
                      {...field}
                      type={"number"}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.screen_brightness[error.type]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("screen_brightness")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="uplink_rate_seconds"
                control={control}
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="uplink_rate_seconds"
                      label={t("common.uplink_rate_seconds")}
                      fullWidth
                      type={"number"}
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              {t("common.seconds")}
                            </InputAdornment>
                          ),
                        },
                      }}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.uplink_rate_seconds[error.type]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("uplink_rate_seconds")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="screen_on_start"
                control={control}
                rules={{
                  number: true,
                  min: 0,
                  max: 23,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="screen_on_start"
                      label={t("common.screen_on_start")}
                      type={"number"}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.screen_on_start[error.type]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("screen_on_start")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="screen_on_end"
                control={control}
                rules={{
                  number: true,
                  min: 0,
                  max: 23,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="screen_on_end"
                      label={t("common.screen_on_end")}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type={"number"}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error ? validationLabels.screen_on_end[error.type] : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("screen_on_end")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setShowLimits(!showLimits)}
            >
              {showLimits ? t("common.hide_limits") : t("common.show_limits")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {addOrUpdate === "add" ? t("common.add") : t("common.update")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withAuthenticationRequired(EstablishmentUpdate);
