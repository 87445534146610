import { withAuthenticationRequired } from "@auth0/auth0-react";
import { MenuItem, TextField } from "@mui/material";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { FormControl } from "@mui/material";
import { postData } from "../API/Post";
import updateSnackbarMessage from "../functions/updateSnackbarMessage";
import { Button } from "@mui/material";
import { Stack } from "@mui/material";
import { putData } from "../API/Put";

function DeviceUpdate() {
  const [addOrUpdate, setAddOrUpdate] = useState("add");
  const [deviceId, setDeviceId] = useState(null);
  const devices = useSelector((state) => state.devices);

  const params = useParams();

  let device = devices.find((device) => device.dev_eui === params.id);
  const { t } = useTranslation("common");

  const { register, handleSubmit, control, reset } = useForm({
    reValidateMode: "onBlur",
  });

  const validationLabels = {
    name: {
      required: t("common.required", { name: t("common.name") }),
    },
    device_type_id: {
      required: t("common.required", { name: t("common.device_type") }),
    },
    dev_eui: {
      required: t("common.required", { name: t("common.dev_eui") }),
      minLength: t("common.min_length", { length: 8 }),
      pattern: t("common.alphanumeric_only"),
    },

    app_eui: {
      minLength: t("common.min_length", { length: 8 }),
      pattern: t("common.alphanumeric_only"),
    },
    app_key: {
      minLength: t("common.min_length", { length: 16 }),
      pattern: t("common.alphanumeric_only"),
    },
  };

  const onSubmit = async (data) => {
    let { name, device_type_id, dev_eui, app_eui, app_key } = data;

    let jsonData = {
      name: name,
      dev_eui: dev_eui,
      app_eui: app_eui,
      app_key: app_key,
      device_type_id: device_type_id,
    };

    try {
      let response = null;

      if (deviceId) {
        response = await putData(`/devices/${deviceId}/`, jsonData);
      } else {
        response = await postData(`/devices/`, jsonData);
      }

      if (response.status === 200) {
        updateSnackbarMessage(
          addOrUpdate === "add"
            ? t("common.added_success", { name: t("common.device") }) +
                " Redirecting to Devices..."
            : t("common.updated_success", { name: t("common.device") }) +
                " Redirecting to Devices..."
        );

        //set timeout for 2 seconds and redirect to /devices
        setTimeout(() => {
          window.location.href = "/devices/";
        }, 3000);
      } else {
        updateSnackbarMessage(
          addOrUpdate === "add"
            ? t("common.add_error", { name: t("common.device") })
            : t("common.update_error", { name: t("common.device") })
        );
      }
    } catch (error) {
      updateSnackbarMessage(
        addOrUpdate === "add"
          ? t("common.add_error", { name: t("common.device") }) + error
          : t("common.update_error", { name: t("common.device") }) + error
      );
    }
  };

  useEffect(() => {
    let msg = t("common.add_new", { name: t("common.device") });

    if (params.id) {
      msg = t("common.update") + " " + t("common.device");
      setDeviceId(params.id);
      setAddOrUpdate("update");

      if (!device) {
        return;
      }

      reset({
        name: device.name,
        device_type_id: device.device_type_id,
        dev_eui: device.dev_eui,
      });
    } else {
      setDeviceId(null);
      setAddOrUpdate("add");
    }

    store.dispatch({
      type: "pageTitle/update",
      payload: msg,
    });
  }, [params, t, devices, reset, device]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} direction={"row"}>
        <Grid item xs={addOrUpdate === "add" ? 6 : 4}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="name"
                  label={t("common.name")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.name[error.type] : ""}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  {...register("name")}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={addOrUpdate === "add" ? 6 : 4}>
          <Controller
            name="device_type_id"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="device_type_id"
                  label={t("common.device_type")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={
                    error ? validationLabels.device_type_id[error.type] : ""
                  }
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  {...register("device_type_id")}
                  select
                  value={field.value ? field.value : 0}
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem key={"device_type_id"} value={1}>
                    {t("common.sen_screen")}
                  </MenuItem>
                  <MenuItem key={"device_type_id"} value={2}>
                    {t("common.milesight_ct101")}
                  </MenuItem>
                  <MenuItem key={"device_type_id"} value={3}>
                    {t("common.quandify_cubic_meter")}
                  </MenuItem>
                </TextField>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="dev_eui"
            disabled={addOrUpdate === "update"}
            control={control}
            rules={{
              required: true,
              minLength: 8,
              pattern: /^[a-zA-Z0-9]*$/,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="dev_eui"
                  label={t("common.dev_eui")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.dev_eui[error.type] : ""}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  {...register("dev_eui")}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            )}
          />
        </Grid>

        {
          //only show these fields if its an add
        }
        {addOrUpdate === "add" && (
          <>
            <Grid item xs={4}>
              <Controller
                name="app_eui"
                control={control}
                rules={{
                  minLength: 8,
                  pattern: /^[a-zA-Z0-9]*$/,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="app_eui"
                      label={t("common.app_eui")}
                      fullWidth
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error ? validationLabels.name[error.type] : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("app_eui")}
                    />
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name="app_key"
                control={control}
                rules={{
                  minLength: 16,
                  //mix of leter and numbers only
                  pattern: /^[a-zA-Z0-9]*$/,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="app_key"
                      label={t("common.app_key")}
                      fullWidth
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error ? validationLabels.name[error.type] : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("app_key")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {addOrUpdate === "add" ? t("common.add") : t("common.update")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withAuthenticationRequired(DeviceUpdate);
