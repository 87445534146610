import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import {
  CorporateFare,
  DevicesRounded,
  LocalOffer,
  MeetingRoom,
  Monitor,
} from "@mui/icons-material";

export default function MenuContent(props) {
  const navigate = (url) => {
    window.location.href = url;
  };

  const mainListItems = [
    {
      text: props.t("common.dashboard"),
      icon: <HomeRoundedIcon />,
      url: "/",
    },
    {
      text: props.t("common.devices"),
      icon: <DevicesRounded />,
      url: "/devices",
    },
    {
      text: props.t("common.screens"),
      icon: <Monitor />,
      url: "/screens",
    },
    { text: props.t("common.brands"), icon: <LocalOffer />, url: "/brands" },
    {
      text: props.t("common.establishments"),
      icon: <CorporateFare />,
      url: "/establishments",
    },
    {
      text: props.t("common.residences"),
      icon: <MeetingRoom />,
      url: "/residences",
    },
  ];

  const secondaryListItems = [
    {
      text: props.t("common.settings"),
      icon: <SettingsRoundedIcon />,
      url: "/settings",
    },
    {
      text: props.t("common.feedback"),
      icon: <HelpRoundedIcon />,
      url: "/feedback",
    },
  ];
  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ display: "block" }}
            onClick={() => navigate(item.url)}
          >
            <ListItemButton selected={window.location.pathname === item.url}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ display: "block" }}
            onClick={() => navigate(item.url)}
          >
            <ListItemButton selected={window.location.pathname === item.url}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
