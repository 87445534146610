import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

export default function PageActions(actions) {
  const pageActions = useSelector((state) => state.pageActions);

  //for each action return a button in a grid, next to each other
  return pageActions.map((action) => (
    <Grid
      item
      key={action.name}
      sx={{
        //align right
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => {
          window.location.href = action.url;
        }}
      >
        {action.name}
      </Button>
    </Grid>
  ));
}
