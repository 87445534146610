const initialState = [];

function screensReducer(state = initialState, action) {
  switch (action.type) {
    case "screens/update":
      return action.payload;
    default:
      return state;
  }
}

export default screensReducer;
