import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./containers/Dashboard";
import Devices from "./containers/Devices";
import Brands from "./containers/Brands";
import Establishments from "./containers/Establishments";
import Residences from "./containers/Residences";
import Screens from "./containers/Screens";
import Settings from "./containers/Settings";
import NotFound from "./containers/NotFound";
import BrandUpdate from "./containers/BrandUpdate";
import EstablishmentUpdate from "./containers/EstablishmentUpdate";
import ResidenceUpdate from "./containers/ResidenceUpdate";
import DeviceUpdate from "./containers/DeviceUpdate";
import ScreenUpdate from "./containers/ScreenUpdate";

export default function AppRoutes(props) {
  return (
    <Routes>
      <Route path="/" exact element={<Dashboard />} />
      <Route path="/devices" element={<Devices />} />
      <Route path="/add/device" element={<DeviceUpdate />} />
      <Route path="/devices/:id" element={<DeviceUpdate />} />
      <Route path="/brands" element={<Brands />} />
      <Route path="/add/brand" element={<BrandUpdate />} />
      <Route path="/brands/:id" element={<BrandUpdate />} />
      <Route path="/establishments" element={<Establishments />} />
      <Route path="/add/establishment" element={<EstablishmentUpdate />} />
      <Route path="/establishments/:id" element={<EstablishmentUpdate />} />
      <Route path="/residences" element={<Residences />} />
      <Route path="/add/residence" element={<ResidenceUpdate />} />
      <Route path="/residences/:id" element={<ResidenceUpdate />} />
      <Route path="/screens" element={<Screens />} />
      <Route path="/screens/:id" element={<ScreenUpdate />} />
      <Route path="/settings" element={<Settings />} />
      {
        //404}
      }
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
