import { useSelector } from "react-redux";
import DataTableBase from "./DataTableBase";
import { useTranslation } from "react-i18next";
import React from "react";
import { Typography, useTheme } from "@mui/material";

export default function EstablishmentsTable() {
  const establishments = useSelector((state) => state.establishments);
  const brands = useSelector((state) => state.brands);

  const theme = useTheme();

  const { t } = useTranslation("common");

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: t("common.name"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography
              variant="body2"
              component="div"
              onClick={() => {
                window.location.href = `/establishments/${tableMeta.rowData[0]}`;
              }}
              sx={{ color: theme.palette.primary.main, cursor: "pointer" }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "stars",
      label: t("common.stars"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          //use the value to show stars
          return (
            //value is int, show stars
            <div>
              {Array.from({ length: value }, (_, index) => (
                <span key={index}>&#9733;</span>
              ))}
            </div>
          );
        },
      },
    },
    {
      name: "brand_id",
      label: t("common.brand"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let brand = brands.find(
            (brand) => parseInt(brand.id) === parseInt(value)
          );

          return brand ? brand.name : "";
        },
      },
    },
  ];

  const [selectedEstablishments, setSelectedestablishments] = React.useState(
    []
  );
  const [selectedRows, setSelectedRows] = React.useState([]);

  const tableActions = [
    {
      name: t("common.delete_selected", {
        name: t("common.establishments"),
        count: selectedEstablishments.length,
      }),
      icon: "Delete",
      action: () => {},
    },
  ];

  return (
    <DataTableBase
      columns={columns}
      data={establishments}
      title={"Establishments"}
      id="establishment-list"
      name="establishment-list"
      pageSize={10}
      selectedItems={selectedEstablishments}
      setSelectedItems={setSelectedestablishments}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      selectableRows="multiple"
      actions={tableActions}
      sortOrder="dev_eui"
    />
  );
}
