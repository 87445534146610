import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from "./translations/en/common.json";
import { CONFIG } from "./config";

const root = ReactDOM.createRoot(document.getElementById("root"));

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: CONFIG.defaultLanguage, // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0ProviderWithHistory>
        <I18nextProvider
          local={CONFIG.defaultLanguage}
          messages={common_en}
          i18n={i18next}
        >
          <App />
        </I18nextProvider>
      </Auth0ProviderWithHistory>
    </Provider>
  </React.StrictMode>
);
