import { withAuthenticationRequired } from "@auth0/auth0-react";
import { store } from "../store";
import { useTranslation } from "react-i18next";

function Settings(props) {
  const { t } = useTranslation("common");

  store.dispatch({ type: "pageTitle/update", payload: t("common.settings") });

  return <></>;
}

export default withAuthenticationRequired(Settings);
