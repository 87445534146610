import { Box, Typography } from "@mui/material";
import { CONFIG } from "../../config";

export default function HeaderLogo(props) {
  //box with logo left, title right and desc below in a smaller font
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <img src="/logo192.png" alt="Logo" width="40" height="40" />
      <Box sx={{ ml: 2 }}>
        <Typography variant="h6" component="h1">
          {CONFIG.title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {CONFIG.version}
        </Typography>
      </Box>
    </Box>
  );
}
