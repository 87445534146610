import { withAuthenticationRequired } from "@auth0/auth0-react";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import ScreensTable from "../components/tables/Screens";

function Screens(props) {
  const { t } = useTranslation("common");

  store.dispatch({ type: "pageTitle/update", payload: t("common.screens") });

  return (
    <Grid item xs={12}>
      <ScreensTable />
    </Grid>
  );
}

export default withAuthenticationRequired(Screens);
