const initialState = [];

function residencesReducer(state = initialState, action) {
  switch (action.type) {
    case "residences/update":
      return action.payload;
    default:
      return state;
  }
}

export default residencesReducer;
