const initialState = "";

function pageTitleReducer(state = initialState, action) {
  switch (action.type) {
    case "pageTitle/update":
      return action.payload;
    default:
      return state;
  }
}

export default pageTitleReducer;
