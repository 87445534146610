import { useSelector } from "react-redux";
import DataTableBase from "./DataTableBase";
import { useTranslation } from "react-i18next";
import React from "react";
import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";

export default function ScreensTable() {
  const screens = useSelector((state) => state.screens);
  const residences = useSelector((state) => state.residences);

  const theme = useTheme();

  const { t } = useTranslation("common");

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "dev_eui",
      label: "Dev EUI",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography
              variant="body2"
              component="div"
              onClick={() => {
                window.location.href = `/screens/${tableMeta.rowData[0]}`;
              }}
              sx={{ color: theme.palette.primary.main, cursor: "pointer" }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "residence_id",
      label: t("common.residence"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let residence = residences.find(
            (residence) => parseInt(residence.id) === parseInt(value)
          );

          return residence ? residence.name : "";
        },
      },
    },
    {
      name: "screen_on",
      label: t("common.screen_on"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? t("common.yes") : t("common.no");
        },
      },
    },
    {
      name: "smiley_color",
      label: t("common.smiley_color"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "water_liter",
      label: t("common.water_liter"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "electricity_kwh",
      label: t("common.electricity_kwh"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "water_color",
      label: t("common.water_color"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "electricity_color",
      label: t("common.electricity_color"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "uplink_rate_seconds",
      label: t("common.uplink_rate_seconds"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "screen_brightness",
      label: t("common.screen_brightness"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "time_updated",
      label: t("common.time_updated"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return new Date(value).toLocaleString();
        },
      },
    },
  ];

  const [selectedScreens, setSelectedscreens] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const tableActions = [
    {
      name: t("common.delete_selected", {
        name: t("common.screens"),
        count: selectedScreens.length,
      }),
      icon: "Delete",
      action: () => {},
    },
  ];

  return (
    <DataTableBase
      columns={columns}
      data={screens}
      title={"Screens"}
      id="screen-list"
      name="screen-list"
      pageSize={10}
      selectedItems={selectedScreens}
      setSelectedItems={setSelectedscreens}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      selectableRows="multiple"
      actions={tableActions}
      sortOrder="dev_eui"
    />
  );
}
