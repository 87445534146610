import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import Layout from "./components/layout/Layout";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMemo, useEffect } from "react";
import { CONFIG } from "./config";

import { useAuth0 } from "@auth0/auth0-react";
import refreshData from "./functions/refreshData";
import { updateThemeMode } from "./functions/updateThemeMode";
import handleThemeChange from "./functions/handleThemeChange";

function App() {
  const { user, getAccessTokenSilently } = useAuth0();
  const themeMode =
    useSelector((state) => state.themeMode) || CONFIG.defaultTheme;
  const theme = useMemo(
    () =>
      createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            ///xxl: apple studio display
            xxl: 2048,
          },
        },
        palette: {
          mode: themeMode === "dark" ? "dark" : "light",
          gradient:
            themeMode === "dark"
              ? "linear-gradient( to bottom, #191B1F 0%, #191B1F 550px, #25282D 550px, #25282D 150%)"
              : "linear-gradient( to bottom, " +
                CONFIG.primaryColour +
                " 0%, " +
                CONFIG.primaryColour +
                " 550px, #EFEFEF 550px , #EFEFEF 150%)",
          primary: {
            main: CONFIG.primaryColour,
          },
          secondary: {
            main: CONFIG.secondaryColour,
          },
        },

        //add a custom style for .MuiPaper-root class hover
      }),
    [themeMode]
  );

  useEffect(() => {
    const themeFromLocal = localStorage.getItem("theme") || CONFIG.defaultTheme;
    updateThemeMode(themeFromLocal);
    handleThemeChange(themeFromLocal);
  }, [themeMode]);

  useEffect(() => {
    if (!user) return;
    async function fetchToken() {
      try {
        const accessToken = await getAccessTokenSilently();
        localStorage.setItem("access_token", accessToken);
      } catch (e) {
        console.log(e.message);
      }
    }

    async function fetchDataAndToken() {
      if (!user) return;

      await fetchToken();
      refreshData(user);
    }

    fetchDataAndToken();
  }, [user, getAccessTokenSilently]);

  useEffect(() => {
    if (!user) return;

    //interval
    let interval = setInterval(async () => {
      await refreshData(user);
    }, 1 * 60 * 1000);

    return () => clearInterval(interval);
  }, [user]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Layout>
          <AppRoutes />
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
