import fetchData from "../API/Fetch";
import { store } from "../store";

export default async function fetchScreens() {
  let result = await fetchData(`/screens/`);

  store.dispatch({
    type: "screens/update",
    payload: result ? result : [],
  });
  return result;
}
