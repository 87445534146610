const initialState = [];

function userDBReducer(state = initialState, action) {
  switch (action.type) {
    case "userDB/update":
      return action.payload;
    default:
      return state;
  }
}

export default userDBReducer;
